import Icon from 'components/dist/atoms/Icon';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Tooltip from 'components/dist/atoms/Tooltip';
import ScreenSizeDropdownMenu from 'components/dist/molecules/ScreenSizeDropdownMenu';
import { ColorPalletteList } from 'src/components/color-pallette-list/color-pallette-list.component';
import { ColorPalletteListItem } from 'src/components/color-pallette-list/color-pallette-list-item';
import { FormElementStorageTypeGuard } from 'src/components/form-elements/form-element-storage-type-guard';
import { PreviewReadOnlyEdit } from 'src/components/form-elements/preview-read-only/preview-read-only-edit.component';
import { LoanRoleGuard } from 'src/components/user/loan-role-guard';
import { JestTestId } from 'src/constants/tests';
import { Permission } from 'src/constants/user';
import { getExtensionFromFilename } from 'src/utils/get-extension-from-filename';
import { classNames } from 'src/utils/tailwind-class-names';

import { FormElementStatusDropdown } from '../form-element-status-dropdown';
import { FormElementStatusPopover } from '../form-element-status-popover';
import { FormElementFileRowActionsProps } from './form-element-row.types';
import { FormElementRowAssign } from './form-element-row-assign';

export const FormElementFileRowActions = (props: FormElementFileRowActionsProps) => {

    return (<Stack
        row
        space="md"
        data-visible={props.isDropdownVisible}
        className={classNames(`lg:w-0 pl-1 group-[.is-actions-visible]:lg:w-auto
        data-[visible=true]:opacity-100 data-[visible=true]:w-auto 
            lg:group-hover:w-auto lg:group-hover:opacity-100  justify-end
            ${props.packageColumnStatus === "FULL_WIDTH" ? "absolute right-0 top-0 h-full md:pr-3 lg:pr-4 xl:pr-5" : "lg:opacity-0"}
            overflow-hidden`, {
            'min-w-6': props.packageColumnStatus === "FULL_WIDTH",
            'group-hover:bg-gray-neutral-30': !props.isActive,
            'bg-blue-50 ': props.isActive,
            // 'sm:w-auto': props.formElement.storageType === "FOLDER"
        })}>
        <FormElementStorageTypeGuard
            requiredType={'FILE'}
            type={props.formElement.storageType}>
            {!props.formElement.answer && !props.hideJitActions && <Tooltip>
                <Tooltip.Trigger>
                    <div
                        aria-label="Upload"
                        aria-hidden={props.isDropdownVisible}
                        data-ui={`${!props.isDropdownVisible ? "active" : ""}`}
                        className='hidden lg:block data-active:group-hover:opacity-100 aria-hidden:opacity-0'
                        data-upload-trigger="true"
                    >
                        <Icon
                            strokeWidth={1.5}
                            name="Upload"
                            width={19}
                            height={19} />
                    </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        aria-hidden={props.isDropdownVisible}
                        align="start"
                        side="bottom"
                        alignOffset={0}
                        className="border-0 z-tooltip"
                        asChild>
                        <div className="p-2 flex rounded-md flex gap-1 items-center">
                            Upload
                        </div>
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip>}
        </FormElementStorageTypeGuard>
        {(props.formElement.answer &&
            !props.hideJitActions &&
            props.isUserAllowedToUpdateStatus &&
            props.formElement.isVirusClean) &&
            <LoanRoleGuard permission={Permission.AcceptDocuments}>
                <FormElementStatusPopover
                    loanRoles={props.loanRoles}
                    withPortal={!props.isNeedsListModal}
                    title={props.formElement.title}
                    loanId={props.formElement.loanId}
                    id={props.formElement.id}
                    hidden={props.isDropdownVisible}
                    status={props.formElement.status}
                />
            </LoanRoleGuard>}
        {props.formElement.answer &&
            !props.hideJitActions &&
            (!props.isLocked || props.formElement.answer?.document?.providerType === "S3") && <Tooltip>
                <Tooltip.Trigger asChild>
                    <button
                        aria-label="Open In New Tab"
                        aria-hidden={props.isDropdownVisible}
                        className='hidden lg:block aria-hidden:opacity-0 data-active:group-hover:opacity-100'
                        type="button"
                        onClick={props.onOpenNewTabClick}>
                        <Icon
                            strokeWidth={1.5}
                            name="MultiWindow" width={20} height={20} />
                    </button>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        aria-hidden={props.isDropdownVisible}
                        align="start"
                        side="bottom"
                        alignOffset={0}
                        className="border-0 z-tooltip"
                        asChild>
                        <div className="p-2 flex rounded-md flex gap-1 items-center">
                            Open In New Tab
                        </div>
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip>}
        {!props.hideJitActions && <LoanRoleGuard
            permission={Permission.EditFormElementTitle}>
            {!props.isRoot && !props.hideJitActions && props.formElement.isVirusClean && <Tooltip>
                <Tooltip.Trigger asChild>
                    <button
                        aria-label={props.editLabel}
                        aria-hidden={props.isDropdownVisible}
                        className='hidden lg:block aria-hidden:opacity-0 data-active:group-hover:opacity-100'
                        type="button"
                        onClick={props.onEditClick}>
                        <Icon
                            strokeWidth={1.5}
                            name={props.editLabel === 'Rename' ? "Edit" : "EditPencil"}
                            width={20}
                            height={20} />
                    </button>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        aria-hidden={props.isDropdownVisible}
                        align="start"
                        side="bottom"
                        alignOffset={0}
                        className="border-0 z-tooltip"
                        asChild>
                        <div className="p-2 flex rounded-md flex gap-1 items-center">
                            {props.editLabel}
                        </div>
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip>}
        </LoanRoleGuard>}
        {!props.hideJitActions &&
            props.formElement.isVirusClean && <FormElementRowAssign
                loanRoles={props.loanRoles}
                loanEntities={props.loanEntities}
                formElement={props.formElement}
                loggedInUserId={props.loggedInUserId}
                isDropdownVisible={props.isDropdownVisible}
                onAssignClick={props.onAssignClick}
            />}
        <ScreenSizeDropdownMenu
            modal={false}
            open={props.isDropdownVisible}
            onOpenChange={props.onChangeDropDownVisibility}>
            <props.DropdownPortal>
                <ScreenSizeDropdownMenu.Trigger asChild>
                    <div className={`${!props.isNeedsListModal ? "fixed" : ""} h-5`}
                        style={{
                            top: props.dropdownPosition.y,
                            left: props.dropdownPosition.x
                        }} />
                </ScreenSizeDropdownMenu.Trigger>
            </props.DropdownPortal>
            <button
                data-visible={props.isDropdownVisible}
                type="button"
                disabled={props.areMultipleElementsChecked}
                aria-label="More Options"
                className={`relative z-1 ${props.areMultipleElementsChecked ? "opacity-20" : ""} group-[.is-actions-visible]:opacity-100`}
                data-id='dropdown-trigger'
                onClick={props.onDropdownTriggerClick}>
                <Icon
                    name="MoreVert"
                    width={20}
                    strokeWidth={1.5} />
            </button>
            {!props.areMultipleElementsChecked && <ScreenSizeDropdownMenu.Content
                withPortal={!props.isNeedsListModal}
                onClick={(e) => e.stopPropagation()}
                side='bottom'
                data-testid={JestTestId.FormElementDropdown}
                align='end'
                className="p-4 pr-0 sm:w-72 bottom-0 gap-0 sm:gap-3">
                {/* Edit Sharepoint */}
                {props.formElement.answer?.document?.providerType === 'SHAREPOINT' &&
                    props.formElement.isVirusClean &&
                    <ScreenSizeDropdownMenu.Sub>
                        <ScreenSizeDropdownMenu.SubTrigger className='h-9'>
                            <Stack
                                row
                                space="sm"
                                className='flex-1'
                            >
                                <Stack
                                    row
                                    space="sm"
                                    className="items-center px-1 cursor-pointer w-full text-sm text-black-primary">
                                    <PreviewReadOnlyEdit
                                        platform='DESKTOP'
                                        extension={getExtensionFromFilename(props.formElement.answer.document.name)} />
                                </Stack>
                            </Stack>
                        </ScreenSizeDropdownMenu.SubTrigger>
                        <ScreenSizeDropdownMenu.Portal>
                            <ScreenSizeDropdownMenu.SubContent
                                withPortal={!props.isNeedsListModal}
                                className="py-2 px-0 gap-0 sm:gap-3 flex flex-col sm:p-2 sm:w-72 overflow-none">
                                <ScreenSizeDropdownMenu.Item
                                    className="h-9 gap-2"

                                    onSelect={() => props.onEditOneDriveFile("DESKTOP")}>
                                    <PreviewReadOnlyEdit
                                        platform='DESKTOP'
                                        extension={getExtensionFromFilename(props.formElement.answer.document.name)} />
                                </ScreenSizeDropdownMenu.Item>
                                <ScreenSizeDropdownMenu.Item
                                    className="h-9 gap-2"
                                    onSelect={() => props.onEditOneDriveFile("WEB")}>
                                    <PreviewReadOnlyEdit
                                        platform='WEB'
                                        extension={getExtensionFromFilename(props.formElement.answer.document.name)} />
                                </ScreenSizeDropdownMenu.Item>
                            </ScreenSizeDropdownMenu.SubContent>
                        </ScreenSizeDropdownMenu.Portal>
                    </ScreenSizeDropdownMenu.Sub>}
                {/* Edit Info */}
                <ScreenSizeDropdownMenu.Sub>
                    <ScreenSizeDropdownMenu.SubTrigger asChild className='h-9'>
                        <Stack items='center' row space='sm' className='gap-2 font-medium text-sm'>
                            <Icon name="Page" width={18} height={18} strokeWidth={2} />
                            Item Information
                        </Stack>
                    </ScreenSizeDropdownMenu.SubTrigger>
                    <ScreenSizeDropdownMenu.Portal>
                        <ScreenSizeDropdownMenu.SubContent
                            withPortal={!props.isNeedsListModal}
                            className="py-2 px-0 gap-0 sm:gap-3 flex flex-col sm:p-2 sm:w-72 overflow-none">
                            {/* Edit Details */}
                            <FormElementStorageTypeGuard
                                requiredType={'FILE'}
                                type={props.formElement.storageType}>
                                <LoanRoleGuard
                                    permission={Permission.EditFormElementTitle}>
                                    {!props.isRoot && <ScreenSizeDropdownMenu.Item
                                        className="h-9"
                                        onSelect={props.onEditDetailsClick}>
                                        <Stack
                                            row
                                            space="sm"
                                            className="items-center w-full cursor-pointer">
                                            <Icon
                                                strokeWidth={2}
                                                name="EditPencil"
                                                width={18}
                                                height={18} />
                                            <Text size="sm" weight='medium'>
                                                Edit details
                                            </Text>
                                        </Stack>
                                    </ScreenSizeDropdownMenu.Item>}
                                </LoanRoleGuard>
                            </FormElementStorageTypeGuard>
                            {/* Duplicate */}
                            <ScreenSizeDropdownMenu.Item
                                disabled={!props.formElement.parentId}
                                className="h-9 shrink-0"
                                onSelect={() => props.onDuplicateClick(props.formElement)} >
                                <Stack
                                    row
                                    space="sm"
                                    className="items-center cursor-pointer w-full">
                                    <Icon
                                        strokeWidth={1.5}
                                        name="DuplicateOutline"
                                        width={20}
                                        height={20} />
                                    <Text size="sm" weight='medium'>Duplicate</Text>
                                </Stack>
                            </ScreenSizeDropdownMenu.Item>
                            {/* Toggle Visibility */}
                            {!props.isNeedsListModal && (
                                <ScreenSizeDropdownMenu.Item className="h-9"
                                    onSelect={props.onToggleVisibility}>
                                    <Stack row space="sm" className="items-center cursor-pointer w-full"
                                    >
                                        <Icon
                                            strokeWidth={1.5}
                                            name={props.formElement.hidden ? "EyeAlt" : "EyeOff"}
                                            width={20}
                                            height={20} />
                                        <Text size="sm" weight='medium'>{props.formElement.hidden ? "Show" : "Hide"}</Text>
                                    </Stack>
                                </ScreenSizeDropdownMenu.Item>
                            )}
                            {props.formElement.storageType === 'FOLDER' && <ScreenSizeDropdownMenu.Sub>
                                <ScreenSizeDropdownMenu.SubTrigger className='h-9 shrink-0'>
                                    <Stack row space='sm' className='pointer-events-none'>
                                        <Icon name="FillColor" width={20} height={20} strokeWidth={2} />
                                        <Text as="div" truncate size='sm' weight='medium'>
                                            Folder Color
                                        </Text>
                                        <ColorPalletteListItem hexColor={props.formElement.color} />
                                    </Stack>
                                </ScreenSizeDropdownMenu.SubTrigger>
                                <ScreenSizeDropdownMenu.Portal>
                                    <ScreenSizeDropdownMenu.SubContent
                                        sideOffset={0}
                                        className='p-2'>
                                        <div className='grid grid-cols-8 sm:grid-cols-4 gap-5 mb-8 sm:mb-0 sm:p-0 sm:border-none rounded-lg border border-gray-neutral p-2'>
                                            <ColorPalletteList
                                                activeColor={props.formElement.color}
                                                onColorSelected={(color) => props.onColorSelected(color)}
                                            />
                                        </div>
                                    </ScreenSizeDropdownMenu.SubContent>
                                </ScreenSizeDropdownMenu.Portal>
                            </ScreenSizeDropdownMenu.Sub>}
                        </ScreenSizeDropdownMenu.SubContent>
                    </ScreenSizeDropdownMenu.Portal>
                </ScreenSizeDropdownMenu.Sub>
                {/* Upload */}
                {!props.isUploadDisabled && <ScreenSizeDropdownMenu.Item
                    onSelect={() => props.onUploadClick()}
                    data-upload-trigger="true"
                    className='h-9'
                >
                    <Stack
                        row
                        space="sm"
                        className="items-center w-full cursor-pointer">
                        <Icon
                            strokeWidth={2}
                            name="Upload"
                            width={19}
                            height={19} />
                        <Text size="sm" weight='medium'>Upload</Text>
                    </Stack>
                </ScreenSizeDropdownMenu.Item>}
                {/* Open In New Tab */}
                <ScreenSizeDropdownMenu.Item
                    className="h-9 cursor-pointer w-full"
                    onSelect={props.onOpenNewTabClick}>
                    <Stack
                        row
                        space="sm"
                        className="items-center">
                        <Icon
                            strokeWidth={2}
                            name="MultiWindow"
                            width={18}
                            height={18} />
                        <Text size="sm" weight='medium'>
                            {props.formElement.storageType === 'FILE' && 'Open In New Tab'}
                            {props.formElement.storageType === 'FOLDER' && 'Open Folder in New Tab'}
                        </Text>
                    </Stack>
                </ScreenSizeDropdownMenu.Item>
                {/* Pin / Unpin */}
                <LoanRoleGuard permission={Permission.PinFormElements}>
                    {!props.isRoot && !props.isNeedsListModal && <ScreenSizeDropdownMenu.Item className="h-9 " onSelect={props.onPinClick}>
                        <Stack row space="sm" className="items-center w-full cursor-pointer" >
                            <Icon
                                strokeWidth={2}
                                name={props.isPinned ? "RemovePin" : "Pin"}
                                width={18}
                                height={18} />
                            <Text size="sm" weight='medium'>{props.isPinned ? "Unpin" : "Pin"}</Text>
                        </Stack>
                    </ScreenSizeDropdownMenu.Item>}
                </LoanRoleGuard>
                {/* Edit Rename */}
                <LoanRoleGuard
                    permission={Permission.EditFormElementTitle}>
                    {!props.isRoot && <ScreenSizeDropdownMenu.Item
                        className="h-9"
                        onSelect={props.onEditClick}>
                        <Stack
                            row
                            space="sm"
                            className="items-center w-full cursor-pointer">
                            <Icon
                                strokeWidth={2}
                                name="Edit"
                                width={18}
                                height={18} />
                            <Text size="sm" weight='medium'>
                                {props.editLabel}
                            </Text>
                        </Stack>
                    </ScreenSizeDropdownMenu.Item>}
                </LoanRoleGuard>
                {/* Assign */}
                <ScreenSizeDropdownMenu.Item className="h-9"
                    onSelect={() => props.onAssignClick()} >
                    <Stack
                        row
                        space="sm"
                        className="items-center cursor-pointer w-full">
                        <Icon
                            strokeWidth={2}
                            name="AddUser"
                            width={20}
                            height={20} />
                        <Text size="sm" weight='medium'>Assign</Text>
                    </Stack>
                </ScreenSizeDropdownMenu.Item>
                {/* Status  */}
                {(!props.isLocked || props.formElement.answer?.document?.providerType === "S3") && <FormElementStorageTypeGuard
                    requiredType={"FILE"}
                    type={props.formElement.storageType}>
                    <LoanRoleGuard permission={Permission.AcceptDocuments}>
                        {props.formElement.answer && props.formElement.isVirusClean && props.isUserAllowedToUpdateStatus && <FormElementStatusDropdown
                            title={props.formElement.title}
                            loanId={props.formElement.loanId}
                            loanRoles={props.loanRoles}
                            id={props.formElement.id}
                            hidden={props.isDropdownVisible}
                            status={props.formElement.status}
                        />}
                    </LoanRoleGuard>
                </FormElementStorageTypeGuard>}
                <Separator />
                {/* Share */}
                <ScreenSizeDropdownMenu.Sub>
                    <ScreenSizeDropdownMenu.SubTrigger asChild className='h-9'>
                        <Stack items='center' row space='sm' className='h-9 font-medium text-sm'>
                            <Icon name="ArrowEmailForward" width={18} height={18} strokeWidth={2} />
                            Share
                        </Stack>
                    </ScreenSizeDropdownMenu.SubTrigger>
                    <ScreenSizeDropdownMenu.SubContent
                        withPortal={!props.isNeedsListModal}
                        className="py-2 px-0 gap-0 sm:gap-3 flex flex-col sm:p-2 sm:w-72 overflow-none">
                        {/* Send a message */}
                        <LoanRoleGuard permission={Permission.BorrowerTeamCommunication}>
                            <ScreenSizeDropdownMenu.Item className="h-9" onSelect={() => props.onSendMessage(props.formElement)}>
                                <Stack row space="sm" className="items-center cursor-pointer w-full">
                                    <Icon
                                        strokeWidth={2}
                                        name="MessageText"
                                        width={20}
                                        height={20} />
                                    <Text size="sm" weight='medium'>Send a message</Text>
                                </Stack>
                            </ScreenSizeDropdownMenu.Item>
                        </LoanRoleGuard>
                        {/* Download */}
                        {props.formElement.answer
                            && (!props.isLocked || props.formElement.answer?.document?.providerType === "S3")
                            && <FormElementStorageTypeGuard
                                type={props.formElement.storageType}
                                requiredType={'FILE'}>
                                <ScreenSizeDropdownMenu.Item className="h-9"
                                    disabled={!props.formElement.isVirusClean}
                                    onSelect={props.onDownloadClick}>
                                    <Stack row space="sm" className="items-center cursor-pointer w-full" >
                                        <Icon
                                            name="DownloadMenu"
                                            strokeWidth={2}
                                            width={18}
                                            height={18} />
                                        <Text size="sm" weight='medium'>Download</Text>
                                    </Stack>
                                </ScreenSizeDropdownMenu.Item>
                            </FormElementStorageTypeGuard>}
                        {/* Copy Link */}
                        <ScreenSizeDropdownMenu.Item
                            onSelect={() => props.onCopyClick(props.formElement.id)}
                            className='h-9'>
                            <Stack row space='sm'>
                                <Icon
                                    strokeWidth={2}
                                    name="Link"
                                    width={19}
                                    height={19} />
                                <Text as='span' size='sm' weight='medium'>
                                    Copy Link
                                </Text>
                            </Stack>
                        </ScreenSizeDropdownMenu.Item>
                    </ScreenSizeDropdownMenu.SubContent>
                </ScreenSizeDropdownMenu.Sub>
                {/* Organize */}
                <ScreenSizeDropdownMenu.Sub>
                    <ScreenSizeDropdownMenu.SubTrigger asChild className='h-9'>
                        <Stack row space='sm' items='center' className='h-9 font-medium text-sm'>
                            <Icon name="FolderOpenOutline" width={18} height={18} strokeWidth={2} />
                            Organize
                        </Stack>
                    </ScreenSizeDropdownMenu.SubTrigger>
                    <ScreenSizeDropdownMenu.SubContent
                        withPortal={!props.isNeedsListModal}
                        className="py-2 px-0 gap-0 sm:gap-3 flex flex-col sm:p-2 sm:w-72 overflow-none">
                        {/* Move to... */}
                        <LoanRoleGuard
                            permission={Permission.MoveElements}>
                            <ScreenSizeDropdownMenu.Item
                                disabled={!props.formElement.isVirusClean}
                                onSelect={props.onMoveToClick}
                                className='h-9'>
                                <Stack row space='sm'>
                                    <Icon
                                        strokeWidth={2}
                                        name="FolderMoveUp"
                                        width={21}
                                        height={18} />
                                    <Text as='span' size='sm' weight='medium'>
                                        Move to...
                                    </Text>
                                </Stack>
                            </ScreenSizeDropdownMenu.Item>
                        </LoanRoleGuard>
                        {/* Copy to... */}
                        <ScreenSizeDropdownMenu.Item
                            disabled={!props.formElement.isVirusClean}
                            onSelect={props.onCopyToClick}
                            className='h-9'>
                            <Stack row space='sm'>
                                <Icon
                                    strokeWidth={2}
                                    name="Copy"
                                    width={20}
                                    height={20} />
                                <Text as='span' size='sm' weight='medium'>
                                    Copy to...
                                </Text>
                            </Stack>
                        </ScreenSizeDropdownMenu.Item>
                    </ScreenSizeDropdownMenu.SubContent>
                </ScreenSizeDropdownMenu.Sub>
                {/* Delete Answer */}
                <Separator />
                {/* Remove */}
                {props.isDeletePermission && props.isDeleteAnswerAllowed && <ScreenSizeDropdownMenu.Sub>
                    <ScreenSizeDropdownMenu.SubTrigger asChild className='h-9'>
                        <Stack items='center' space='sm' row className='h-9 font-medium text-destructive'>
                            <Icon name="Trash" className='text-inherit' width={18} height={18} strokeWidth={2} />
                            Remove
                        </Stack>
                    </ScreenSizeDropdownMenu.SubTrigger>
                    <ScreenSizeDropdownMenu.SubContent
                        withPortal={!props.isNeedsListModal}
                        className="py-2 px-0 gap-0 sm:gap-3 flex flex-col sm:p-2 sm:w-72 overflow-none">
                        {props.isDeleteAnswerAllowed && <FormElementStorageTypeGuard
                            type={props.formElement.storageType}
                            requiredType={'FILE'}>
                            <ScreenSizeDropdownMenu.Item
                                onSelect={props.onDeleteAnswerClick}
                                disabled={!props.formElement.answer}
                                className="h-9">
                                <Stack
                                    row
                                    space="sm"
                                    className="items-center cursor-pointer"
                                >
                                    <Icon
                                        strokeWidth={2}
                                        name="Trash"
                                        width={18}
                                        height={18}
                                        className="text-destructive"
                                    />
                                    <Text size="sm" variant="destructive" weight='medium'>
                                        Remove Answer
                                    </Text>
                                </Stack>
                            </ScreenSizeDropdownMenu.Item>
                        </FormElementStorageTypeGuard>}
                        <LoanRoleGuard permission={Permission.DeleteFormElements}>
                            <FormElementStorageTypeGuard
                                type={props.formElement.storageType}
                                requiredType={'FILE'}>
                                <ScreenSizeDropdownMenu.Item className="h-9" onSelect={props.onDeleteElementClick}>
                                    <Stack
                                        row
                                        space="sm"
                                        className="items-center cursor-pointer"
                                    >
                                        <Icon
                                            name="Trash"
                                            strokeWidth={2}
                                            width={18}
                                            height={18}
                                            className="text-destructive"
                                        />
                                        <Text size="sm" variant="destructive" weight='medium'>
                                            Remove File Request
                                        </Text>
                                    </Stack>
                                </ScreenSizeDropdownMenu.Item>
                            </FormElementStorageTypeGuard>
                        </LoanRoleGuard>
                    </ScreenSizeDropdownMenu.SubContent>
                </ScreenSizeDropdownMenu.Sub>}
                {props.isDeleteAnswerAllowed && !props.isDeletePermission && <FormElementStorageTypeGuard
                    type={props.formElement.storageType}
                    requiredType='FILE'>
                    <ScreenSizeDropdownMenu.Item
                        onSelect={props.onDeleteAnswerClick}
                        disabled={!props.formElement.answer}
                        className="h-9">
                        <Stack
                            row
                            space="sm"
                            className="items-center cursor-pointer"
                        >
                            <Icon
                                strokeWidth={2}
                                name="Trash"
                                width={18}
                                height={18}
                                className="text-destructive"
                            />
                            <Text size="sm" variant="destructive" weight='medium'>
                                Remove Answer
                            </Text>
                        </Stack>
                    </ScreenSizeDropdownMenu.Item>
                </FormElementStorageTypeGuard>}
                {/* Delete Element */}
                {!props.isDeleteDisabled && <LoanRoleGuard permission={Permission.DeleteFormElements}>
                    {!props.formElement.answer && <FormElementStorageTypeGuard
                        type={props.formElement.storageType}
                        requiredType='FILE'>
                        <ScreenSizeDropdownMenu.Item className="h-9" onSelect={props.onDeleteElementClick}>
                            <Stack
                                row
                                space="sm"
                                className="items-center cursor-pointer"
                            >
                                <Icon
                                    name="Trash"
                                    strokeWidth={2}
                                    width={18}
                                    height={18}
                                    className="text-destructive"
                                />
                                <Text size="sm" variant="destructive" weight='medium'>
                                    Remove File Request
                                </Text>
                            </Stack>
                        </ScreenSizeDropdownMenu.Item>
                    </FormElementStorageTypeGuard>}
                    <FormElementStorageTypeGuard
                        type={props.formElement.storageType}
                        requiredType='FOLDER'>
                        <ScreenSizeDropdownMenu.Item className='h-9' onSelect={props.onDeleteElementClick}>
                            <Stack
                                row
                                space="sm"
                                className="items-center cursor-pointer flex-1" >
                                <Icon
                                    name="Trash"
                                    width={18}
                                    height={18}
                                    strokeWidth={2}
                                    className="text-destructive"
                                />
                                <Text size="sm" variant='destructive' weight='medium'>
                                    Remove
                                </Text>
                            </Stack>
                        </ScreenSizeDropdownMenu.Item>
                    </FormElementStorageTypeGuard>
                </LoanRoleGuard>}
            </ScreenSizeDropdownMenu.Content >}
        </ScreenSizeDropdownMenu>
    </Stack >
    );

};