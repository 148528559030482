import { classNames } from "src/utils/tailwind-class-names";

interface Props {
    hexColor: string;
    isActive?: boolean;
    onColorSelected?: (color: string) => void;
    size?: 'sm' | 'md' | 'lg';
}

export const ColorPalletteListItem = (props: Props) => <button
    type='button'
    onClick={() => props.onColorSelected?.(props.hexColor)}
    className={classNames("data-[active=true]:outline data-[active=true]:border-white border-2 border-transparent outline-blue-60 aspect-square rounded-full", {
        'w-6': props.size === 'sm',
        'w-8': props.size === 'md',
        'w-12': props.size === 'lg',
        'w-5': !props.size,
    })}
    data-active={props.isActive}
    style={{
        backgroundColor: props.hexColor
    }} />